import React, { Component, useState } from "react";
import { Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from "store/student/actions";
import { CrossIcon } from "../../../../assets/image/svg";
import * as teacherActions from "store/teacher/actions";
import * as userActions from "store/user/actions";
import { storage as LocalStorage } from "services/config/storage";
import { FaLock, FaLockOpen } from "react-icons/fa";
import CreateAssignment from "../../../../components/AddAssignment/CreateAssignment/CreateAssignment";

const GuidedPathUnitsModal = ({
  units,
  visible,
  closeModal,
  section,
  updateGPUnit,
  updateAssignments,
  reduxData,
  showToast,
  setShowToast,
}) => {
  const [createAssignmentModalOpen, setCreateAssignmentModalOpen] = useState({
    showModal: false,
    data: null,
  });

  const currentChild = LocalStorage.fetch.defaultChild();

  const handleLock = async (id) => {
    console.log("function called", id);
    const data = {
      id,
      is_unlocked: false,
    };
    const res = await updateGPUnit(data, {
      grade: currentChild?.grade?.id,
      section: section,
    });
    if (res) {
      setShowToast("The Unit is Locked.");
    }
  };
  const handleUnLock = async (id) => {
    const data = {
      id,
      is_unlocked: true,
    };
    let res = await updateGPUnit(data, {
      grade: currentChild?.grade?.id,
      section: section,
    });
    if (res) {
      setShowToast("The Unit is Unlocked.");
    }
    //    fetchAllUnits()
  };
  return (
    <>
      <Modal
        wrapClassName="guided_path_units_modal"
        maskStyle={{
          background: "rgba(0,0,0,0.45)",
        }}
        title="List of Units"
        width={window.innerWidth > 768 ? "85%" : "100%"}
        height={"100%"}
        visible={visible}
        onOk={null}
        onCancel={closeModal}
        okText="Submit"
        closeIcon={
          <i
            aria-label="icon: close"
            className="anticon anticon-close ant-modal-close-icon"
          >
            <CrossIcon />
          </i>
        }
      >
        <div className="gp_units_container">
          {reduxData && reduxData?.teacher?.guidedPathUnits ? (
            reduxData?.teacher?.guidedPathUnits.length > 0 ? (
              reduxData?.teacher?.guidedPathUnits.map((unit) => (
                <div className="unit_card">
                  <h6>{unit.unit.name}</h6>
                  <p className="unit_desc">{unit.unit.description}</p>

                  <div className="guided_path_btn_container">
                    {LocalStorage.fetch.defaultChild()?.school_details
                      ?.custom_lock_unlock &&
                      (unit.is_unlocked ? (
                        <button
                          className="gp_unit_status_btn"
                          onClick={() => handleLock(unit.unit.id)}
                        >
                          <p>Unlocked</p>
                          <FaLockOpen className="unit_lock_unlock_icon" />
                        </button>
                      ) : (
                        <button
                          className="gp_unit_status_btn"
                          onClick={() => handleUnLock(unit.unit.id)}
                        >
                          <p>Locked</p>
                          <FaLock className="unit_lock_unlock_icon" />
                        </button>
                      ))}
                    {LocalStorage.fetch.parentData()?.is_teacher &&
                      unit?.is_unlocked && (
                        <button
                          className="gp_unit_status_btn"
                          onClick={() => {
                            if (unit.is_unlocked) {
                              setCreateAssignmentModalOpen({
                                showModal: true,
                                data: {
                                  id: unit.unit.id,
                                  name: unit.unit.name,
                                  content_type: "guidedpath-unit",
                                },
                              });
                            } else {
                              return;
                            }
                          }}
                        >
                          Assign
                        </button>
                      )}
                  </div>
                </div>
              ))
            ) : (
              <p
                style={{
                  margin: "auto",
                  height: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: "1.3rem",
                }}
              >
                No Units available.
              </p>
            )
          ) : (
            <p
              style={{
                margin: "auto",
                height: "100%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                fontSize: "1.3rem",
              }}
            >
              Your school has not subscribed to Freadom Guided Learning. Please
              reach out to your school for more details.
            </p>
          )}
        </div>
      </Modal>

      <Modal
        centered
        visible={
          createAssignmentModalOpen.showModal && createAssignmentModalOpen.data
        }
        onCancel={() =>
          setCreateAssignmentModalOpen({
            showModal: false,
            data: null,
          })
        }
        footer={null}
        key={1}
        destroyOnClose
        closable={false}
        style={{ minWidth: "40vw", width: "100%" }}
      >
        <CreateAssignment
          data={createAssignmentModalOpen.data}
          type={"Guided Path Unit"}
          updateAssignments={updateAssignments}
          onCloseModal={() =>
            setCreateAssignmentModalOpen({
              showModal: false,
              data: null,
            })
          }
        />
      </Modal>
    </>
  );
};

export default connect(
  (state) => ({ reduxData: { ...state } }),
  (dispatch) =>
    bindActionCreators(
      { ...studentActions, ...teacherActions, ...userActions },
      dispatch
    )
)(GuidedPathUnitsModal);
